import getDocumentTimeElapsed from '~/utils/getDocumentTimeElapsed';

export default function throttleRAF(
  callback = (rafId: number): void => {},
  FPS: number = 60,
): number {
  let timeStamp = getDocumentTimeElapsed();

  const rafHandler = (): void => {
    if ((getDocumentTimeElapsed() - timeStamp) < (1000 / FPS)) {
      return requestAnimationFrame(rafHandler);
    }

    timeStamp = getDocumentTimeElapsed();
    callback(requestAnimationFrame(rafHandler));
  };

  return requestAnimationFrame(rafHandler);
}
